
































import { Component, Prop, Vue } from "vue-property-decorator";
import colors from "@/styles/colors";
import Picture, { ImageObject, ImageSize } from "@/components/Picture.vue";
import TextArea from "@/components/TextArea.vue";
import Button, { ButtonSizes } from "@/components/Button.vue";

export interface GridCellObject {
  title: string;
  description: string;
  image: ImageObject;
  button?: string;
  buttonLink?: string;
}

@Component({
  name: "Grid",
  components: {
    Picture,
    TextArea,
    Button,
  },
})
export default class Grid extends Vue {
  @Prop() gridCells!: GridCellObject[];
  private gridImageSize: ImageSize = ImageSize.medium;
  private buttonColor: string = colors.get().accent;
  private buttonSize: ButtonSizes = ButtonSizes.small;

  private goToLink(value: string) {
    window.open(value);
  }
}
