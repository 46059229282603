























































import { Component, Prop, Vue } from "vue-property-decorator";
import Picture, { ImageObject, ImageSize } from "@/components/Picture.vue";
import TextArea from "@/components/TextArea.vue";
import Button, { ButtonSizes } from "@/components/Button.vue";
import colors from "@/styles/colors";

export interface ListItem {
  title: string;
  description: string;
  image: ImageObject;
  button?: string;
  buttonLink?: string;
}

@Component({
  name: "List",
  components: {
    Picture,
    TextArea,
    Button,
  },
})
export default class List extends Vue {
  @Prop() list!: ListItem[];

  private buttonSize: ButtonSizes = ButtonSizes.medium;
  private buttonColor: string = colors.get().accent;
  private listImageSize: ImageSize = ImageSize.auto;

  private goToLink(value: string) {
    window.open(value);
  }
}
